import $ from 'jquery'

// // Consider upgrading it's consumer to use the NoticeManagerComponent
// Auto-hide
let timeoutID = null

const clearNoticeTimeout = function () {
  if (timeoutID) {
    clearTimeout(timeoutID)
  }
  timeoutID = null
}

const scheduleHide = function () {
  clearNoticeTimeout()
  timeoutID = setTimeout(hideNotice, 10 * 1000)
}

const centerNoticeContainer = function () {
  const window_width = $(window).width()
  $('#notice-container').show()
  const content_width = $('.master-alert').width()
  $('#notice-container').css({
    left:
      $(window).scrollLeft() +
      (content_width > window_width
        ? 0
        : Math.floor((window_width - content_width) / 2)),
  })
}

const hideNotice = function () {
  clearNoticeTimeout()
  $('#notice-container').html('').hide()
  $(window).off('resize.notice-container')
}

const aNoticeAppeared = function () {
  centerNoticeContainer()
  $(window).on('resize.notice-container', centerNoticeContainer)
  // Do not hide in test env to avoid timing issues.
  // Also don't hide error notices, ie. type == 'alert'
  if (
    $('body[data-permanotices=1]').length === 0 &&
    $('.master-alert.alert-alert').length === 0
  ) {
    scheduleHide()
  }
}

window.showNotice = function (type, message) {
  const icon = $('<i>').addClass(
    `fa fa-${type === 'notice' ? 'check' : 'exclamation-triangle'}`
  )
  const alert = $('<div></div>')
    .addClass(`master-alert alert alert-${type} animated bounceIn`)
    .append(icon, message)
  $('#notice-container').html(alert)
  aNoticeAppeared()
}

window.onDomChanged('.master-alert', () => aNoticeAppeared())

$(document).on('click.notice-container', '#notice-container', function (e) {
  if (e.target.tagName.toLowerCase() !== 'a') {
    e.preventDefault()
  }
  hideNotice()
})
